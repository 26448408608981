<template>
  <v-row class="h-full" align="center" justify="center" id="step3-right-page">
    <v-col cols="6">
      <v-btn block type="button" class="white--text" large color="primary" @click="$emit('next')">
          Create New Opportunity
          <v-icon right dark>mdi-chevron-right</v-icon>
      </v-btn>
      <div class="text-center ma-2">OR</div>
      <div class="skeleton-list-item w-full" v-if="donateSearchLoading">
          <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
      </div>
      <v-autocomplete v-if="!donateSearchLoading" v-model="model.sf_id" @change="existingLPChange($event)"
          item-value="sf_id" :item-text="item => `#${item.sf_id} - $${item.amount} Monthly. Last txn on ${$helpers.getMomentDatas('DD/MM/YY',
          item.last_successful_transaction || item.created_at)} by ${item.payment_type_label ? item.payment_type_label : 'Credit Card'}`" hide-details="auto" label="Select Opportunity"
          :items="existingLpLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
          outlined
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "stepThree",
  props: ["v"],
  emits: ["next", 'selectSF'],
  data() {
    return {
      search: '',
      existingLpLists: [],
    }
  },
  computed: {
    ...mapGetters(["donateSearchLoading"]),
    model: {
      get() {
        return this.$store.getters["model"];
      },
      set(newValue) {
        return this.$store.commit("setModel", newValue);
      },
    }
  },
  created() {
    this.model = {...{
      }, ...this.model};
    if (this.model.id) this.getExistingLists();
  },
  methods: {
    ...mapActions(['donateSearch']),

    getExistingLists() {
      this.donateSearch({ contact_id: this.model.id, filter: 'lp-entry'})
        .then(resp => {
          this.existingLpLists = resp.data.data;
        });
    },
    existingLPChange(evt) {
      if (evt) {
        const selectedItem = this.existingLpLists.find(item => item.sf_id == evt);
        this.$emit('selectSF', selectedItem.id);
      } else {
        this.$emit('selectSF', null);
      }
    }
  }
};
</script>
